import Parse from "./parse.service";
import base64toBlob from "base64toblob";

const Transaction = Parse.Object.extend("Transaction");
import { auth, accountAPI } from "./index";

  // Add a new Transaction
  export const transaction = async (_account, amount, detail, attachment, date) => {
    const transaction = new Transaction();
  
    if (attachment) {
      transaction.set(
        "attachment",
        "https://bahikhatabills.s3.amazonaws.com/cb97925b28803a604429df0b4fb43f6f_download1.png"
      );
      const name = attachment.name;
      var parseFile = new Parse.File(name, attachment);
      const result = await parseFile.save();
      transaction.set("attachment", result._url);
    }
  
    if (!!_account) {
      console.log(_account);
      const myAccount = await accountAPI.getAccountByName(_account);
      if (myAccount) {
        transaction.set("accountId", myAccount);
        transaction.set("amount", amount);
        transaction.set("detail", detail);
        transaction.set("cancelled", false);
        transaction.set("date", date);
        transaction.set("userId", auth.fetchUser());
        accountAPI.updateBalance(myAccount.id, amount);
      } else {
        const acc = await accountAPI.account(_account, "");
        if (acc) {
          transaction.set("accountId", acc);
          transaction.set("amount", amount);
          transaction.set("detail", detail);
          transaction.set("date", date);
          transaction.set("cancelled", false);
          transaction.set("userId", auth.fetchUser());
          acc.set("balance", acc.get("balance") + amount);
          await acc.save();
        }
      }
    } else {
      transaction.set("amount", amount);
      transaction.set("detail", detail);
      transaction.set("date", date);
      transaction.set("cancelled", false);
      transaction.set("userId", auth.fetchUser());
    }
    return transaction.save();
  }
  
  export const uploadFile = async (file) => {
    if(file){
      try {
        const name = file.name;
        var parseFile = new Parse.File(name, file);
        var result = await parseFile.save();
        return result;
      } catch (error) {
        console.error("Error uploading file:", error);
        return null;
      }
    }
  }

  export const getTransactionsByAccountIdWithMonth = async (accountId, currentDate) => {
    const startOfMonth = new Date(currentDate.getFullYear(),currentDate.getMonth(),
        1,0,0,0,0);
    const endOfMonth = new Date(currentDate.getFullYear(),currentDate.getMonth()+1,
      0,23,59,59,999);
    
    const account = await accountAPI.getAccountById(accountId);
    const query = new Parse.Query(Transaction);

    query.limit(1000000);
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("accountId", account);
    query.greaterThanOrEqualTo("date", startOfMonth);
    query.lessThanOrEqualTo("date", endOfMonth);
    query.ascending("date");
    query.exclude("userId");
    query.exclude("accountId");
    const results = await query.find();
    return results;
  }
  
  export const getTransactionsByDate = async (date) => {
    const query = new Parse.Query(Transaction);
    const firstDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1,
      23,59,59,999);
    const nextDate = new Date(date.getFullYear(),date.getMonth(),date.getDate(),
      23, 59, 60, 0);
    // query.include("accountId");
    query.limit(1000000);
  
    query.equalTo("userId", auth.fetchUser());
    query.greaterThan("date", firstDate);
    query.lessThan("date", nextDate);
    query.exclude("userId");
    return await query.find();
  }
  
  export const getAllTransactions = async() => {
    try {
      const query = new Parse.Query(Transaction);
      query.limit(1000000);
      query.ascending("date");
      query.equalTo("userId", auth.fetchUser());
      return await query.find();
    } catch (error) {
      console.error(error);
      return [];
    }
  };
  
  export const getTxnsByAccountId = async (id) => {
    const account = await accountAPI.getAccountById(id);
    const query = new Parse.Query(Transaction);
    query.limit(1000000);
    query.ascending("date");
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("accountId", account);
    query.exclude("userId");
    return await query.find();
  }
  
  export const getTxnById = async (id) => {
    const query = new Parse.Query(Transaction);
    query.equalTo("userId", auth.fetchUser());
    query.equalTo("objectId", id);
    query.limit(1);
    const results = await query.find();
    return results[0];
  }
  
  export const updateTransaction = async (id, newamount, _detail, date) => {
    const transaction = await getTransactionById(id)
    const oldamount = transaction.get("amount");
    const account_object = transaction.get("accountId");
    const diffamount = newamount - oldamount;
    transaction.set("amount", newamount);
    if (account_object) {
      const accountId = account_object.id;
      accountAPI.updateBalance(accountId, diffamount);
    }
    transaction.set("detail", _detail);
    transaction.set("date", date);
    return transaction.save();
  }
  
  export const deleteTransaction = async (id) => {
    const txn = await getTransactionById(id)
    txn.set("cancelled", true);
    // var accountId = txn.get('accountId');
    return txn.save();
  }
  
  export const sumTillDate = async (date) => {
    const query = new Parse.Query(Transaction);
    query.limit(100000);
    query.equalTo("userId", auth.fetchUser());
    query.lessThanOrEqualTo("date", date);
    query.equalTo("cancelled", false);
    query.exclude("userId");
    const results = await query.find();
    return results.reduce((sum,result) => sum +result.get("amount"), 0);
  }
  
  export const sumTillDateByAccount = async (date, id) => {
    const account = await accountAPI.getAccountById(id);
    if (!account) return 0;
    const query = new Parse.Query(Transaction);
    query.limit(1000000);
  
    query.equalTo("accountId", account);
    query.lessThanOrEqualTo("date", date);
    query.equalTo("cancelled", false);
    query.exclude("userId");
    const results = await query.find();
    if (results) {
      var sum = 0;
      results.map((result) => (sum += result.get("amount")));
      return sum;
    }
    return 0;
  }
