import Parse from './parse.service'

export const fetchUser = () => {
    return Parse.User.current()
}

export function logout() {
    return Parse.User.logOut()
}

export const sendVerificationEmail = async (email) => {
    try {
        const user = await fetchUser();
        // const randomEmail = 'someone@anymail.com'
        // user.set('email', randomEmail)
        // await user.save()
        user.set('email', email);
        var result = await user.save();
        return result;
    } catch (err) {
        return false
    }
}

export async function loginUsingSession(sessionToken) {
    return await Parse.User.become(sessionToken)
      .then((res) => {
        return { status: "success", response: res };
      }).catch((error) => {
        return { status: "error", response: error };
      });
  }
  