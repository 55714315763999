import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import DatePicker from "react-date-picker";
import { transactionAPI } from "services";
import TxnList from "./TxnList";
import { Loading } from 'components';
//import { useOnboardingStore } from "store/onboarding-store";
import useUniversalStore from "store/universalStore";
import ExpandableButton from "components/ExpandableButton";
import NewTxn from "views/forms/NewTxn";

function Home() {
  const { t } = useTranslation();
  const [date, setDate] = useState(new Date());
  //const goToNextStep = useOnboardingStore((state) => state.goToNextStep);
  const write = useUniversalStore((state) => state.write);
  
  useEffect(() => {
     fetchTransactions();
  }, [date]);

  const fetchTransactions = async () => {
    var transactions = await transactionAPI.getTransactionsByDate(date);
    write({transactions: transactions});
  }

  const prevDate = async () => {
    const newDate = moment(date).subtract(1, "days").toDate();
    setDate(newDate);
  };
  
  const nextDate = async () => {
    const today = new Date();
    if(date >= today) return;
    
    const newDate = moment(date).add(1, "days").toDate();
    setDate(newDate);
  };

  //if(!txns) return <Loading enabled={true} />;
  return (
    <>
      <div className="container-fluid">
        <div className="row bg-app py-1">
          <div className="col-1" align="right">
            <a onClick={prevDate} className="btn btn-outline-light ">
              <FontAwesomeIcon
                icon={faArrowCircleLeft}
                size="2x"
                title="Previous Date"
              />
            </a>
          </div>
          <div className="col-2 offset-md-4 py-2" align="center">
            <DatePicker
              value={date}
              onChange={(value) => setDate(value)}
              monthAriaLabel="Month"
              clearIcon={null}
              format="dd-MM-yyyy"
              maxDate={new Date()}
              maxDetail="month"
              className="datePicker"
              calendarIcon={<i className="bi bi-calendar-date text-white"></i>}
              showMonthYearPicker={false}
            />
          </div>
          <div className="col-1 offset-4" align="left">
            <a
              onClick={nextDate}
              className="btn btn-outline-light "
              disabled={date < new Date()}
            >
              <FontAwesomeIcon
                icon={faArrowCircleRight}
                size="2x"
                title="Next Date"
              />
            </a>
          </div>
        </div>

        <TxnList currentDate={date} />

        <div className="row position-absolute mb-5 mx-5 bottom-0 end-0">
          <ExpandableButton
            title="New Entry"
            clickForm="addTxnDrawer"
            // onClick={(e) => goToNextStep()}
            icon={faPencil} />
        </div>
        
        <NewTxn />
      </div>
    </>
  );
}

export default Home;
