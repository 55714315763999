import React, { useEffect, useState } from "react";
const accounting = require("accounting");
import moment from "moment";
import { FaFilePdf } from "react-icons/fa";

import ModalImage from "react-modal-image";
import { ConfirmPopup } from "components";
import useUniversalStore from "store/universalStore";

function Txn({ data }) {
  const transactionId = useUniversalStore((state) => state.transactionId);
  const write = useUniversalStore((state) => state.write);

  const attachment = data.get("attachment");
  const isPDF = attachment?.includes(".pdf");
  const isImage = attachment?.match(/\.(jpg|jpeg|png|gif)$/i);
  
  const deleteTransaction = async () => {
    await api.deleteTransaction(transactionId);
    write({transactionId: null});
  }

  const editTransaction = () => {
    write({transactionId: data.id});
  }

  const cancelledView = () => {
    return (
      <>
        <div className="col-3 px-0" align="right">
          <i className="text-decoration-line-through text-muted">
            {accounting.formatMoney(Math.abs(data.get("amount")), "₹", 0)}
          </i>
        </div>
        <div className="col-9">
          <div className="row">
            <div
              className="text-decoration-line-through text-muted col-8"
              align="left"
            >
              {data.get("detail")}
            </div>
            <div
              className="text-decoration-line-through text-muted fst-italic col-4"
              align="right"
            >
              {moment(data.get("date")).format("DD MMM")}
            </div>
          </div>
        </div>
      </>
    );
  };

  const normalView = () => {
    return (
      <div className="row">
        <div className="col-3 px-0" align="right">
          <i className="">
            {accounting.formatMoney(Math.abs(data.get("amount")), "₹", 0)}
          </i>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col-8" align="left">
              {data.get("detail")}
            </div>
            <div className="col-4" align="right">
              <b>
                <i>{moment(data.get("date")).format("DD MMM")}</i>
              </b>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              {isPDF && (
                <a
                  role="button"
                  href={attachment}
                  target="_blank"
                  className="hover:opacity-50"
                  style={{ cursor: "pointer" }}
                >
                  <FaFilePdf
                    size={200}
                    className="ms-2 text-danger text-decoration-none pe-none "
                    title="Download PDF"
                  />
                </a>
              )}

              {isImage && (
                <ModalImage
                  small={attachment}
                  large={attachment}
                  className="my-2 img-fluid rounded-2 border border-2 border-danger"
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-1">
          {data.id ? (
            <a
              className="text-black btn bg-not-transparent-on-hover w-auto ms-auto"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              title="More options"
              align="right"
            >
              <span className="bi bi-three-dots-vertical"></span>
            </a>
          ) : null}
          <ul className="dropdown-menu row w-auto">
            <li className="col-12">
              <button
                className="w-100 btn btn-outline-dark rounded"
                data-bs-toggle="offcanvas"
                data-bs-target="#editTxnDrawer"
                aria-controls="editTxnDrawer"
                onClick={() => {
                  editTransaction();
                }}
              >
                <span className="bi bi-pencil-fill"> Edit</span>
              </button>
            </li>
            <li className="col-12 py-1">
              <button className="w-100 btn btn-danger rounded"
                data-bs-toggle="modal"
                data-bs-target="#confirmModal">
                <i className="bi bi-trash"> Delete</i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <li className="list-group-item bg-transparent">
      <div className="row align-items-baseline">
        {data.get("cancelled") ? cancelledView() : normalView()}
      </div>
    </li>
  );
}

export default Txn;
