import { useState, useEffect } from "react";
import useUniversalStore from "store/universalStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { toggleOffCanvas } from "utils";
import { accountAPI } from "services";

const SubMenu = ({account}) => {
    const write = useUniversalStore((state) => state.write);
    //const accountId = useUniversalStore((state) => state.accountId);
    //const [account, setAccount] = useState(null);

    // Reopen a closed account
    const reOpenAccount = async (e) => {
        e.preventDefault();
        const result = await accountAPI.reopenAccount(account.id);
        if(result){
            // Update the accounts in the global state
            const accounts = await accountAPI.getAccounts();
            write({ accounts: accounts });
        }
    }

    const editAccount = (e) => {
        e.preventDefault();
        toggleOffCanvas('editAccountDrawer');
    }

    return (
        <div className="dropdown">
            <a className="rounded-circle btn bg-not-transparent-on-hover"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false">
                <i className="bi bi-three-dots-vertical"></i>
            </a>
            <ul className="dropdown-menu">
                <li className="py-1 dropdown-item bg-transparent">
                    {account?.get("cancelled") ? 
                        <button className="w-100 btn btn-outline-dark"
                            onClick={reOpenAccount}>
                            <i className="bi bi-arrow-clockwise"></i>
                            <span className="mx-3">Reopen Account</span>
                        </button> :
                        <button className="w-100 btn btn-outline-dark"
                            onClick={editAccount}>
                            <FontAwesomeIcon icon={faPencil} size="1x" />
                            <span className="mx-3">Edit</span>
                        </button>
                    }
                </li>
                {account?.get("balance") == 0 && !account?.get("cancelled") ? (
                    <li className="py-1 dropdown-item bg-transparent">
                        <button className="w-100 btn btn-outline-danger"
                            data-bs-toggle="modal" 
                            data-bs-target="#confirmationModal">
                                <i className="bi bi-x-circle-fill"></i>
                                <span className="mx-3">Close</span>
                        </button>
                    </li>
                ) : null}
            </ul>
        </div>
    )
}

export default SubMenu;