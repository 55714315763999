import Parse from "./parse.service";
import { auth } from "services";

const Profile = Parse.Object.extend("Profile");
const user = auth.fetchUser();

export const getProfile = async () => {
    const query = new Parse.Query(Profile);
    query.equalTo("userId", user);
    query.exclude("userId");
    const results = await query.find();
    return results[0];
}

export const updateProfile = async (profileData) => {
    try {
        const profile = await getProfile();
        profile.set("userId", user);
        profileData.phone? profile.set("phone", profileData.phone) : null;
        profileData.email? profile.set("email", profileData.email) : null;
        profileData.businessName? profile.set("businessName", profileData.businessName) : null;
        profileData.typeOfBusiness? profile.set("typeOfBusiness", profileData.typeOfBusiness) : null;
        profileData.photo? profile.set("profilePhoto", profileData.profilePhoto) : null;
        return profile.save();
    } catch(error){
        console.error("Error updating profile:", error);
    }
}

export function setOnboardingInfo(status) {
    user.set('onboardingInfo', status)
    return user.save();
}

export function setTypeOfBusiness(typeOfBusiness) {
    user.set('typeOfBusiness', typeOfBusiness)
    return user.save();
}