import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowCircleLeft, faArrowLeft, faImage } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-date-picker';
import { api, transactionAPI } from 'services';

import useUniversalStore from "store/universalStore";
// import txnaudio from "../images/google_pay-[AudioTrimmer.com].mp3"

const EditTxn = (props) => {
  const [values,setValues] = useState({
    amount: 0,
    multipler: 1,
    details: "",
    date: new Date()
  })

  const transactionId = useUniversalStore(state => state.transactionId);
  const write = useUniversalStore((state) => state.write);

  // useEffect(() => {
  //   api.getActiveAccounts().then((accounts) => {
  //     setAccounts(accounts);
  //   });
  // }, []);

  useEffect(() => {
    if(!transactionId) return
    // console.log(updateData);
      if (transactionId?.id) {
        if (transactionId.get("accountId")) {
          let accname = transactionId.get("accountId").get("name");
          document.getElementById("edittxnacc").value = accname ? accname : "";
        }
        if (transactionId.get("amount") < 0) {
          setValues((prev)=>{
            let tempVar = {...prev}
            tempVar.multipler = -1
            return tempVar
          })
        } else {
          setValues((prev)=>{
            let tempVar = {...prev}
            tempVar.multipler = 1
            return tempVar
          })
        }
        setValues((prev)=>{
          let tempVar = {...prev}
          tempVar.amount = transactionId.get("amount")
          tempVar.date = transactionId.get("date")
          tempVar.detials= transactionId.get("detail")
          return tempVar
        })

      }
      console.log(transactionId.get("amount"));
  }, [transactionId]);

  const amountChangeHandler = (e) => {
    e.preventDefault();
    if (e.target.value < 0) {
      setValues((prev)=>{
        let tempVar = {...prev}
        tempVar.multipler = -1
        tempVar.amount= (-e.target.value);
        return tempVar
      })

    } else {
      setValues((prev)=>{
        let tempVar = {...prev}
        tempVar.amount= (e.target.value);
        return tempVar
      })
    }
  }

  const submitTransactionEdit = async (e) => {
    e.preventDefault();
    if (values.date !== transactionId.get("date")) {
      const transactionDate = transactionId.get("date");
      values.date.setHours(transactionDate.getHours(),
        transactionDate.getMinutes(), transactionDate.getSeconds());
    }
    if (values.amount > 0 && values.amount <= 9999999) {
      const result = await transactionAPI.updateTransaction(transactionId.id, 
        values.multipler * values.amount, values.details, values.date);
      
      //Store data in global store.
      write({transactionId: result});
      // Clear the form
      resetform();
    } else {
      alert("Enter an amount between 1 and ₹1,00,00,000")
    }

  }

  // const updateTransactionsState = (newTxn) => {
  //   const arrayIndex = copiedTransactions.findIndex(obj => obj.id == newTxn.id);
  //   copiedTransactions[arrayIndex] = newTxn;
  //   dispatch(getTransctions(copiedTransactions));
  // }

  document.getElementById('editTxnDrawer')?.addEventListener('hide.bs.offcanvas', function (e) {
    resetform();
  });

  const resetform = () => {
    let txnform = document.getElementById("EditTxnform");
    setValues({amount:0,multipler:1,detials:"",date:new Date()})
    txnform.reset();
    document.getElementById("edittxnacc").value = "";
  }

  return (
    <div className="offcanvas offcanvas-end text-danger" tabIndex="-1" id="editTxnDrawer" aria-labelledby="offcanvasExampleLabel">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Edit Transaction</h3>
        <button type="button" className="btn-close text-reset bg-light" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => resetform()}></button>
      </div>
      <div className="offcanvas-body">
        <div className="container-fluid bg-white mb-5 row col-12">
          <form onSubmit={submitTransactionEdit}>
            <div className="row py-3">
              <div className="col-md-6">
                <input type="number" className="d-none" id="editmultipler" />
                <input type="number" className="form-control" id="edittxnamount" placeholder="Amount" value={values.amount} onChange={amountChangeHandler} min="1" max="9999999"  required title="You cannot edit amount" />
              </div>
              <div className="col-md-6 border-present mt-4 mt-md-0">
                <DatePicker className="form-control w-100" id="edittxndate" 
                  value={values.date} 
                  onChange={(dateValue)=>{
                    setValues((prev)=>{
                      let tempVar = {...prev}
                      tempVar.date = dateValue
                      return tempVar
                    })
                  }} maxDate={new Date()} />
              </div>
            </div>
            <div className="py-3">
              <input type="text" className="form-control" id="edittxnacc" 
                placeholder="Account Name" list="accountOptions" 
                title="You cannnot edit account" disabled />
            </div>
            <div className="py-3">
              <input type="text" className="form-control" id="edittxndetail" 
                placeholder="Details" maxLength="50" value={values.details} 
                onChange={(e) => {
                  setValues((prev)=> {
                    let tempVar = {...prev}
                    tempVar.details = e.target.value
                    return tempVar
                  })
                }} />
            </div>
            <div className="row my-3">
              <div className="col-md-12 d-grid">
                <button type="submit" className="btn btn-outline-danger block py-3" data-bs-dismiss="offcanvas">
                  <b className="fs-5">Update</b>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
export default EditTxn;
