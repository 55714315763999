import React, { useEffect, useState } from "react";
import { accountAPI } from "services";
import "react-phone-number-input/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toggleOffCanvas } from "utils";
import { Status, ProfilePhoto, Loader } from "components";
import useUniversalStore from "store/universalStore";

import { auth } from "services";

const EditAccount = () => {
  //TODO : Merge this with AddAccount
  //TODO : Currently address, detail, phone no. are coming garbage values if nothing present.
  //TODO : Show progress when image uploading

  const [invalidPhone, setInvalidPhone] = useState(true);
  const accountId = useUniversalStore((state) => state.accountId);
  const write = useUniversalStore((state) => state.write);

  const initialState = {
    name: "",
    phone: "",
    address: "",
    detail: "",
    photo: { url: null, loading: false },
    loading: true,
    status: { type: null, message: "" },
  };

  const [values, setValues] = useState(initialState);
  
  const updateField = (field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }

  const handleProfilePhotoChange = async (e) => {
    if(e.target.files.length == 0){
      //when upload file dialog is cancelled without picking any file.
      updateField("photo", null);
    } else if (e.target.files) {
      //upload file
      //var result = await api.uploadFile(e.target.files[0]);
      
      //update profile photo in state
      //updateField("photo", {url: result._url, loading: true});

      //TODO : save image uploaded in global state
      
      //TODO : show progress when image uploading
      
    }
  };

  const fetchAccountData = async () => {    
    const account = await accountAPI.getAccountById(accountId);
    if(account){
      setValues((prev) => ({
        ...prev,
        loading: false,
        name: account?.get("name"),
        phone: account?.get("phone"),
        address: account?.get("address"),
        detail: account?.get("detail"),
        photo: { url: account?.get("photo") || null, loading: false},
        status: { type: null, message: "" },
      }));
    }
  }

  useEffect(() => {
    const offCanvas = document.getElementById("editAccountDrawer");
    if(!offCanvas) return;

    const handleHidden = () => {
      setValues(initialState);
    };

    const handleShown = () => {
      fetchAccountData();
    };

    offCanvas.addEventListener("hidden.bs.offcanvas", handleHidden);
    offCanvas.addEventListener("shown.bs.offcanvas", handleShown);

    return () => {
      //remove event listeners when offcanvas is hidden
      offCanvas.removeEventListener("hidden.bs.offcanvas", handleHidden);
      offCanvas.removeEventListener("shown.bs.offcanvas", handleShown);
    };
  },[accountId]);

  useEffect(() => {
    setInvalidPhone(
      !(isValidPhoneNumber("+91" + values.phone) || values.phone === "")
    );
  }, [values.phone]);

  const updateAccount = async (e) => {
    e.preventDefault();
    setValues((prev) => ({ ...prev, loading: true }));
    
    try {
      setValues({
        ...initialState, 
        loading: false,
        status: { 
          type:"success", message: "Account Updated Successfully!" 
        }
      });

      //Update selected account data
      await accountAPI.updateAccount(
        accountId,values.name,values.phone,values.address,
        values.detail,values.photo.url);
      
      //Update accounts in global store
      write({accounts: await accountAPI.getAccounts()});

      //Reset form and close offcanvas
      setTimeout(() => {
        toggleOffCanvas("editAccountDrawer");
        setValues({
          ...initialState, 
          status: { type: null, message: "" } 
        });
      }, 100);
      
    } catch (error) {
      setValues((prev) => ({
        ...prev,
        status: { type: "error", message: error.message },
      }));
    }
  };

  return (
    <div className="offcanvas offcanvas-end text-danger"
      tabIndex="-1"
      id="editAccountDrawer">
      <div className="offcanvas-header bg-app text-light">
        <h3 className="offcanvas-title">Edit Account</h3>
        <button
          type="button"
          className="btn-close text-reset bg-light"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body container-fluid">
          <div className="row">
            <div className="col-12">
              {values.loading ? <Loader /> :
                <form onSubmit={updateAccount} id="editaccountform">
                  <div className="my-5" align="center">
                  <ProfilePhoto 
                    url={values.photo.url}
                    loading={values.photo.loading} 
                    onChange={handleProfilePhotoChange} />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`form-control`}
                    placeholder="Account Name"
                    value={values.name}
                    onChange={(e) => updateField("name", e.target.value)}
                    required />
                </div>
                <div className="mb-3">
                  <input
                    type="number"
                    className={`form-control ${
                      invalidPhone ? "is-invalid" : values.phone ? "is-valid" : ""
                    }`}
                    placeholder="Phone no."
                    value={values.phone}
                    onChange={(e) => updateField("phone", e.target.value)} />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className={`form-control ${values.address ? "is-valid" : ""}`}
                    placeholder="Address"
                    value={values.address}
                    onChange={(e) => updateField("address", e.target.value)} />
                </div>
                <div className="mb-3">
                  <textarea
                    type="text"
                    className={`form-control ${values.markup ? "is-valid" : ""}`}
                    placeholder="Other Details"
                    value={values.detail}
                    onChange={(e) => updateField("detail", e.target.value)} />
                </div>
                <Status type={values.status.type} message={values.status.message} />
                <div className="row my-3 sticky-bottom-drawer">
                  <div className="col-md-12 d-grid">
                    <button
                      type="submit"
                      className="btn btn-outline-danger py-3 fs-5 fw-bold"
                      disabled={!values.name || invalidPhone}>
                      UPDATE
                    </button>
                  </div>
                </div>
                </form>
              }
            </div>
          </div>
      </div>
    </div>
  );
};

export default EditAccount;
